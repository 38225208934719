<template>
  <svg
    viewBox="299.578 150.924 100.311 95.314"
    shape-rendering="geometricPrecision"
    text-rendering="geometricPrecision"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <radialGradient
        id="eDlETs86x0k19-fill"
        cx="0"
        cy="0"
        r="0.5"
        spreadMethod="pad"
        gradientUnits="objectBoundingBox"
        gradientTransform="matrix(1 0 0 1 0.5 0.5)"
      >
        <stop
          id="eDlETs86x0k19-fill-0"
          offset="0"
          stop-color="rgb(217,255,57)"
        />
        <stop
          id="eDlETs86x0k19-fill-1"
          offset="1"
          stop-color="rgba(217,255,57,0)"
        />
      </radialGradient>
    </defs>
    <g id="eDlETs86x0k2" transform="matrix(0.2, 0, 0, 0.2, 290, 140)">
      <g id="eDlETs86x0k3_tr" transform="translate(300,300) rotate(0)">
        <g id="eDlETs86x0k3_ts" transform="scale(1,1)">
          <g id="eDlETs86x0k3" transform="translate(-300,-300)">
            <g id="eDlETs86x0k4">
              <g
                id="eDlETs86x0k5_ts"
                transform="translate(300,300) rotate(180) scale(1,1)"
              >
                <path
                  id="eDlETs86x0k5"
                  d="M-200,0C-200,-49.222006,-110.45695,-89.124326,0,-89.124326C110.45695,-89.124326,200,-49.222006,200,0C200,49.222006,110.45695,89.124326,0,89.124326C-110.45695,89.124326,-200,49.222006,-200,0Z"
                  transform="translate(0,0)"
                  opacity="0.15"
                  fill="none"
                  stroke="rgb(217,255,57)"
                  stroke-width="10"
                  stroke-linecap="round"
                  stroke-dasharray="900,42.16"
                />
              </g>
              <g
                id="eDlETs86x0k6_ts"
                transform="translate(300,300) rotate(180) scale(1,1)"
              >
                <path
                  id="eDlETs86x0k6"
                  d="M-200,0C-200,-49.222006,-110.45695,-89.124326,0,-89.124326C110.45695,-89.124326,200,-49.222006,200,0C200,49.222006,110.45695,89.124326,0,89.124326C-110.45695,89.124326,-200,49.222006,-200,0Z"
                  transform="translate(0,0)"
                  opacity="0.38"
                  fill="none"
                  stroke="rgb(217,255,57)"
                  stroke-width="10"
                  stroke-linecap="round"
                  stroke-dasharray="400,542.16"
                />
              </g>
              <g
                id="eDlETs86x0k7_ts"
                transform="translate(300,300) rotate(180) scale(1,1)"
              >
                <path
                  id="eDlETs86x0k7"
                  d="M-200,0C-200,-49.222006,-110.45695,-89.124326,0,-89.124326C110.45695,-89.124326,200,-49.222006,200,0C200,49.222006,110.45695,89.124326,0,89.124326C-110.45695,89.124326,-200,49.222006,-200,0Z"
                  transform="translate(0,0)"
                  fill="none"
                  stroke="rgb(217,255,57)"
                  stroke-width="10"
                  stroke-linecap="round"
                  stroke-dasharray="100,842.16"
                />
              </g>
              <g
                id="eDlETs86x0k8_ts"
                transform="translate(300,300) rotate(180) scale(1,1)"
              >
                <path
                  id="eDlETs86x0k8"
                  d="M-200,0C-200,-49.222006,-110.45695,-89.124326,0,-89.124326C110.45695,-89.124326,200,-49.222006,200,0C200,49.222006,110.45695,89.124326,0,89.124326C-110.45695,89.124326,-200,49.222006,-200,0Z"
                  transform="translate(0,0)"
                  fill="none"
                  stroke="rgb(255,255,255)"
                  stroke-width="20"
                  stroke-linecap="round"
                  stroke-dasharray="0.01,942.16"
                />
              </g>
            </g>
            <g
              id="eDlETs86x0k9"
              transform="matrix(-0.5 0.866025 -0.866025 -0.5 709.807621 190.192379)"
            >
              <g
                id="eDlETs86x0k10_ts"
                transform="translate(300,300) rotate(180) scale(1,1)"
              >
                <path
                  id="eDlETs86x0k10"
                  d="M-200,0C-200,-49.222006,-110.45695,-89.124326,0,-89.124326C110.45695,-89.124326,200,-49.222006,200,0C200,49.222006,110.45695,89.124326,0,89.124326C-110.45695,89.124326,-200,49.222006,-200,0Z"
                  transform="translate(0,0)"
                  opacity="0.15"
                  fill="none"
                  stroke="rgb(217,255,57)"
                  stroke-width="10"
                  stroke-linecap="round"
                  stroke-dasharray="900,42.16"
                />
              </g>
              <g
                id="eDlETs86x0k11_ts"
                transform="translate(300,300) rotate(180) scale(1,1)"
              >
                <path
                  id="eDlETs86x0k11"
                  d="M-200,0C-200,-49.222006,-110.45695,-89.124326,0,-89.124326C110.45695,-89.124326,200,-49.222006,200,0C200,49.222006,110.45695,89.124326,0,89.124326C-110.45695,89.124326,-200,49.222006,-200,0Z"
                  transform="translate(0,0)"
                  opacity="0.38"
                  fill="none"
                  stroke="rgb(217,255,57)"
                  stroke-width="10"
                  stroke-linecap="round"
                  stroke-dasharray="400,542.16"
                />
              </g>
              <g
                id="eDlETs86x0k12_ts"
                transform="translate(300,300) rotate(180) scale(1,1)"
              >
                <path
                  id="eDlETs86x0k12"
                  d="M-200,0C-200,-49.222006,-110.45695,-89.124326,0,-89.124326C110.45695,-89.124326,200,-49.222006,200,0C200,49.222006,110.45695,89.124326,0,89.124326C-110.45695,89.124326,-200,49.222006,-200,0Z"
                  transform="translate(0,0)"
                  fill="none"
                  stroke="rgb(217,255,57)"
                  stroke-width="10"
                  stroke-linecap="round"
                  stroke-dasharray="100,842.16"
                />
              </g>
              <g
                id="eDlETs86x0k13_ts"
                transform="translate(300,300) rotate(180) scale(1,1)"
              >
                <path
                  id="eDlETs86x0k13"
                  d="M-200,0C-200,-49.222006,-110.45695,-89.124326,0,-89.124326C110.45695,-89.124326,200,-49.222006,200,0C200,49.222006,110.45695,89.124326,0,89.124326C-110.45695,89.124326,-200,49.222006,-200,0Z"
                  transform="translate(0,0)"
                  fill="none"
                  stroke="rgb(255,255,255)"
                  stroke-width="20"
                  stroke-linecap="round"
                  stroke-dasharray="0.01,942.16"
                />
              </g>
            </g>
            <g
              id="eDlETs86x0k14"
              transform="matrix(-0.5 -0.866025 0.866025 -0.5 190.192379 709.807621)"
            >
              <g
                id="eDlETs86x0k15_ts"
                transform="translate(300,300) rotate(180) scale(1,1)"
              >
                <path
                  id="eDlETs86x0k15"
                  d="M-200,0C-200,-49.222006,-110.45695,-89.124326,0,-89.124326C110.45695,-89.124326,200,-49.222006,200,0C200,49.222006,110.45695,89.124326,0,89.124326C-110.45695,89.124326,-200,49.222006,-200,0Z"
                  transform="translate(0,0)"
                  opacity="0.15"
                  fill="none"
                  stroke="rgb(217,255,57)"
                  stroke-width="10"
                  stroke-linecap="round"
                  stroke-dasharray="900,42.16"
                />
              </g>
              <g
                id="eDlETs86x0k16_ts"
                transform="translate(300,300) rotate(180) scale(1,1)"
              >
                <path
                  id="eDlETs86x0k16"
                  d="M-200,0C-200,-49.222006,-110.45695,-89.124326,0,-89.124326C110.45695,-89.124326,200,-49.222006,200,0C200,49.222006,110.45695,89.124326,0,89.124326C-110.45695,89.124326,-200,49.222006,-200,0Z"
                  transform="translate(0,0)"
                  opacity="0.38"
                  fill="none"
                  stroke="rgb(217,255,57)"
                  stroke-width="10"
                  stroke-linecap="round"
                  stroke-dasharray="400,542.16"
                />
              </g>
              <g
                id="eDlETs86x0k17_ts"
                transform="translate(300,300) rotate(180) scale(1,1)"
              >
                <path
                  id="eDlETs86x0k17"
                  d="M-200,0C-200,-49.222006,-110.45695,-89.124326,0,-89.124326C110.45695,-89.124326,200,-49.222006,200,0C200,49.222006,110.45695,89.124326,0,89.124326C-110.45695,89.124326,-200,49.222006,-200,0Z"
                  transform="translate(0,0)"
                  fill="none"
                  stroke="rgb(217,255,57)"
                  stroke-width="10"
                  stroke-linecap="round"
                  stroke-dasharray="100,842.16"
                />
              </g>
              <g
                id="eDlETs86x0k18_ts"
                transform="translate(300,300) rotate(180) scale(1,1)"
              >
                <path
                  id="eDlETs86x0k18"
                  d="M-200,0C-200,-49.222006,-110.45695,-89.124326,0,-89.124326C110.45695,-89.124326,200,-49.222006,200,0C200,49.222006,110.45695,89.124326,0,89.124326C-110.45695,89.124326,-200,49.222006,-200,0Z"
                  transform="translate(0,0)"
                  fill="none"
                  stroke="rgb(255,255,255)"
                  stroke-width="20"
                  stroke-linecap="round"
                  stroke-dasharray="0.01,942.16"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      <g
        id="eDlETs86x0k19_ts"
        transform="translate(300,300) scale(2.206103,2.206103)"
      >
        <ellipse
          id="eDlETs86x0k19"
          rx="69.65964"
          ry="69.65964"
          transform="translate(0,0)"
          opacity="0.25"
          fill="url(#eDlETs86x0k19-fill)"
          stroke="none"
          stroke-width="0"
        />
      </g>
      <ellipse
        id="eDlETs86x0k20"
        rx="50.092138"
        ry="50.092138"
        transform="matrix(1 0 0 1 300 300)"
        fill="rgb(217,255,57)"
        stroke="none"
        stroke-width="0"
      />
      <g id="eDlETs86x0k21_tr" transform="translate(300,300) rotate(250)">
        <ellipse
          id="eDlETs86x0k21"
          rx="34.999738"
          ry="34.999738"
          transform="translate(0,0)"
          fill="none"
          stroke="rgb(255,255,255)"
          stroke-width="10"
          stroke-linecap="round"
          stroke-dasharray="0.01,20,40,159.9"
        />
      </g>
    </g>
  </svg>
</template>

<style>
#eDlETs86x0k3_tr {
  animation: eDlETs86x0k3_tr__tr 1200ms linear infinite normal forwards;
}
@keyframes eDlETs86x0k3_tr__tr {
  0% {
    transform: translate(300px, 300px) rotate(0deg);
  }
  100% {
    transform: translate(300px, 300px) rotate(120deg);
  }
}
#eDlETs86x0k3_ts {
  animation: eDlETs86x0k3_ts__ts 1200ms linear infinite normal forwards;
}
@keyframes eDlETs86x0k3_ts__ts {
  0% {
    transform: scale(1, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  50% {
    transform: scale(0.9, 0.9);
  }
  100% {
    transform: scale(1, 1);
  }
}
#eDlETs86x0k5_ts {
  animation: eDlETs86x0k5_ts__ts 1200ms linear infinite normal forwards;
}
@keyframes eDlETs86x0k5_ts__ts {
  0% {
    transform: translate(300px, 300px) rotate(180deg) scale(1, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  50% {
    transform: translate(300px, 300px) rotate(180deg) scale(1, 1.3);
  }
  100% {
    transform: translate(300px, 300px) rotate(180deg) scale(1, 1);
  }
}
#eDlETs86x0k5 {
  animation: eDlETs86x0k5_s_do 1200ms linear infinite normal forwards;
}
@keyframes eDlETs86x0k5_s_do {
  0% {
    stroke-dashoffset: 0;
    animation-timing-function: cubic-bezier(0, 0, 0.75, 0.5);
  }
  25% {
    stroke-dashoffset: 235.54;
    animation-timing-function: cubic-bezier(0.25, 0.5, 1, 1);
  }
  50% {
    stroke-dashoffset: 471.08;
    animation-timing-function: cubic-bezier(0, 0, 0.75, 0.5);
  }
  75% {
    stroke-dashoffset: 706.62;
    animation-timing-function: cubic-bezier(0.25, 0.5, 1, 1);
  }
  100% {
    stroke-dashoffset: 942.16;
  }
}
#eDlETs86x0k6_ts {
  animation: eDlETs86x0k6_ts__ts 1200ms linear infinite normal forwards;
}
@keyframes eDlETs86x0k6_ts__ts {
  0% {
    transform: translate(300px, 300px) rotate(180deg) scale(1, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  50% {
    transform: translate(300px, 300px) rotate(180deg) scale(1, 1.3);
  }
  100% {
    transform: translate(300px, 300px) rotate(180deg) scale(1, 1);
  }
}
#eDlETs86x0k6 {
  animation: eDlETs86x0k6_s_do 1200ms linear infinite normal forwards;
}
@keyframes eDlETs86x0k6_s_do {
  0% {
    stroke-dashoffset: 0;
    animation-timing-function: cubic-bezier(0, 0, 0.75, 0.5);
  }
  25% {
    stroke-dashoffset: 235.54;
    animation-timing-function: cubic-bezier(0.25, 0.5, 1, 1);
  }
  50% {
    stroke-dashoffset: 471.08;
    animation-timing-function: cubic-bezier(0, 0, 0.75, 0.5);
  }
  75% {
    stroke-dashoffset: 706.62;
    animation-timing-function: cubic-bezier(0.25, 0.5, 1, 1);
  }
  100% {
    stroke-dashoffset: 942.16;
  }
}
#eDlETs86x0k7_ts {
  animation: eDlETs86x0k7_ts__ts 1200ms linear infinite normal forwards;
}
@keyframes eDlETs86x0k7_ts__ts {
  0% {
    transform: translate(300px, 300px) rotate(180deg) scale(1, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  50% {
    transform: translate(300px, 300px) rotate(180deg) scale(1, 1.3);
  }
  100% {
    transform: translate(300px, 300px) rotate(180deg) scale(1, 1);
  }
}
#eDlETs86x0k7 {
  animation: eDlETs86x0k7_s_do 1200ms linear infinite normal forwards;
}
@keyframes eDlETs86x0k7_s_do {
  0% {
    stroke-dashoffset: 0;
    animation-timing-function: cubic-bezier(0, 0, 0.75, 0.5);
  }
  25% {
    stroke-dashoffset: 235.54;
    animation-timing-function: cubic-bezier(0.25, 0.5, 1, 1);
  }
  50% {
    stroke-dashoffset: 471.08;
    animation-timing-function: cubic-bezier(0, 0, 0.75, 0.5);
  }
  75% {
    stroke-dashoffset: 706.62;
    animation-timing-function: cubic-bezier(0.25, 0.5, 1, 1);
  }
  100% {
    stroke-dashoffset: 942.16;
  }
}
#eDlETs86x0k8_ts {
  animation: eDlETs86x0k8_ts__ts 1200ms linear infinite normal forwards;
}
@keyframes eDlETs86x0k8_ts__ts {
  0% {
    transform: translate(300px, 300px) rotate(180deg) scale(1, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  50% {
    transform: translate(300px, 300px) rotate(180deg) scale(1, 1.3);
  }
  100% {
    transform: translate(300px, 300px) rotate(180deg) scale(1, 1);
  }
}
#eDlETs86x0k8 {
  animation: eDlETs86x0k8_s_do 1200ms linear infinite normal forwards;
}
@keyframes eDlETs86x0k8_s_do {
  0% {
    stroke-dashoffset: 0;
    animation-timing-function: cubic-bezier(0, 0, 0.75, 0.5);
  }
  25% {
    stroke-dashoffset: 235.54;
    animation-timing-function: cubic-bezier(0.25, 0.5, 1, 1);
  }
  50% {
    stroke-dashoffset: 471.08;
    animation-timing-function: cubic-bezier(0, 0, 0.75, 0.5);
  }
  75% {
    stroke-dashoffset: 706.62;
    animation-timing-function: cubic-bezier(0.25, 0.5, 1, 1);
  }
  100% {
    stroke-dashoffset: 942.16;
  }
}
#eDlETs86x0k10_ts {
  animation: eDlETs86x0k10_ts__ts 1200ms linear infinite normal forwards;
}
@keyframes eDlETs86x0k10_ts__ts {
  0% {
    transform: translate(300px, 300px) rotate(180deg) scale(1, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  50% {
    transform: translate(300px, 300px) rotate(180deg) scale(1, 1.3);
  }
  100% {
    transform: translate(300px, 300px) rotate(180deg) scale(1, 1);
  }
}
#eDlETs86x0k10 {
  animation: eDlETs86x0k10_s_do 1200ms linear infinite normal forwards;
}
@keyframes eDlETs86x0k10_s_do {
  0% {
    stroke-dashoffset: 0;
    animation-timing-function: cubic-bezier(0, 0, 0.75, 0.5);
  }
  25% {
    stroke-dashoffset: 235.54;
    animation-timing-function: cubic-bezier(0.25, 0.5, 1, 1);
  }
  50% {
    stroke-dashoffset: 471.08;
    animation-timing-function: cubic-bezier(0, 0, 0.75, 0.5);
  }
  75% {
    stroke-dashoffset: 706.62;
    animation-timing-function: cubic-bezier(0.25, 0.5, 1, 1);
  }
  100% {
    stroke-dashoffset: 942.16;
  }
}
#eDlETs86x0k11_ts {
  animation: eDlETs86x0k11_ts__ts 1200ms linear infinite normal forwards;
}
@keyframes eDlETs86x0k11_ts__ts {
  0% {
    transform: translate(300px, 300px) rotate(180deg) scale(1, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  50% {
    transform: translate(300px, 300px) rotate(180deg) scale(1, 1.3);
  }
  100% {
    transform: translate(300px, 300px) rotate(180deg) scale(1, 1);
  }
}
#eDlETs86x0k11 {
  animation: eDlETs86x0k11_s_do 1200ms linear infinite normal forwards;
}
@keyframes eDlETs86x0k11_s_do {
  0% {
    stroke-dashoffset: 0;
    animation-timing-function: cubic-bezier(0, 0, 0.75, 0.5);
  }
  25% {
    stroke-dashoffset: 235.54;
    animation-timing-function: cubic-bezier(0.25, 0.5, 1, 1);
  }
  50% {
    stroke-dashoffset: 471.08;
    animation-timing-function: cubic-bezier(0, 0, 0.75, 0.5);
  }
  75% {
    stroke-dashoffset: 706.62;
    animation-timing-function: cubic-bezier(0.25, 0.5, 1, 1);
  }
  100% {
    stroke-dashoffset: 942.16;
  }
}
#eDlETs86x0k12_ts {
  animation: eDlETs86x0k12_ts__ts 1200ms linear infinite normal forwards;
}
@keyframes eDlETs86x0k12_ts__ts {
  0% {
    transform: translate(300px, 300px) rotate(180deg) scale(1, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  50% {
    transform: translate(300px, 300px) rotate(180deg) scale(1, 1.3);
  }
  100% {
    transform: translate(300px, 300px) rotate(180deg) scale(1, 1);
  }
}
#eDlETs86x0k12 {
  animation: eDlETs86x0k12_s_do 1200ms linear infinite normal forwards;
}
@keyframes eDlETs86x0k12_s_do {
  0% {
    stroke-dashoffset: 0;
    animation-timing-function: cubic-bezier(0, 0, 0.75, 0.5);
  }
  25% {
    stroke-dashoffset: 235.54;
    animation-timing-function: cubic-bezier(0.25, 0.5, 1, 1);
  }
  50% {
    stroke-dashoffset: 471.08;
    animation-timing-function: cubic-bezier(0, 0, 0.75, 0.5);
  }
  75% {
    stroke-dashoffset: 706.62;
    animation-timing-function: cubic-bezier(0.25, 0.5, 1, 1);
  }
  100% {
    stroke-dashoffset: 942.16;
  }
}
#eDlETs86x0k13_ts {
  animation: eDlETs86x0k13_ts__ts 1200ms linear infinite normal forwards;
}
@keyframes eDlETs86x0k13_ts__ts {
  0% {
    transform: translate(300px, 300px) rotate(180deg) scale(1, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  50% {
    transform: translate(300px, 300px) rotate(180deg) scale(1, 1.3);
  }
  100% {
    transform: translate(300px, 300px) rotate(180deg) scale(1, 1);
  }
}
#eDlETs86x0k13 {
  animation: eDlETs86x0k13_s_do 1200ms linear infinite normal forwards;
}
@keyframes eDlETs86x0k13_s_do {
  0% {
    stroke-dashoffset: 0;
    animation-timing-function: cubic-bezier(0, 0, 0.75, 0.5);
  }
  25% {
    stroke-dashoffset: 235.54;
    animation-timing-function: cubic-bezier(0.25, 0.5, 1, 1);
  }
  50% {
    stroke-dashoffset: 471.08;
    animation-timing-function: cubic-bezier(0, 0, 0.75, 0.5);
  }
  75% {
    stroke-dashoffset: 706.62;
    animation-timing-function: cubic-bezier(0.25, 0.5, 1, 1);
  }
  100% {
    stroke-dashoffset: 942.16;
  }
}
#eDlETs86x0k15_ts {
  animation: eDlETs86x0k15_ts__ts 1200ms linear infinite normal forwards;
}
@keyframes eDlETs86x0k15_ts__ts {
  0% {
    transform: translate(300px, 300px) rotate(180deg) scale(1, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  50% {
    transform: translate(300px, 300px) rotate(180deg) scale(1, 1.3);
  }
  100% {
    transform: translate(300px, 300px) rotate(180deg) scale(1, 1);
  }
}
#eDlETs86x0k15 {
  animation: eDlETs86x0k15_s_do 1200ms linear infinite normal forwards;
}
@keyframes eDlETs86x0k15_s_do {
  0% {
    stroke-dashoffset: 0;
    animation-timing-function: cubic-bezier(0, 0, 0.75, 0.5);
  }
  25% {
    stroke-dashoffset: 235.54;
    animation-timing-function: cubic-bezier(0.25, 0.5, 1, 1);
  }
  50% {
    stroke-dashoffset: 471.08;
    animation-timing-function: cubic-bezier(0, 0, 0.75, 0.5);
  }
  75% {
    stroke-dashoffset: 706.62;
    animation-timing-function: cubic-bezier(0.25, 0.5, 1, 1);
  }
  100% {
    stroke-dashoffset: 942.16;
  }
}
#eDlETs86x0k16_ts {
  animation: eDlETs86x0k16_ts__ts 1200ms linear infinite normal forwards;
}
@keyframes eDlETs86x0k16_ts__ts {
  0% {
    transform: translate(300px, 300px) rotate(180deg) scale(1, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  50% {
    transform: translate(300px, 300px) rotate(180deg) scale(1, 1.3);
  }
  100% {
    transform: translate(300px, 300px) rotate(180deg) scale(1, 1);
  }
}
#eDlETs86x0k16 {
  animation: eDlETs86x0k16_s_do 1200ms linear infinite normal forwards;
}
@keyframes eDlETs86x0k16_s_do {
  0% {
    stroke-dashoffset: 0;
    animation-timing-function: cubic-bezier(0, 0, 0.75, 0.5);
  }
  25% {
    stroke-dashoffset: 235.54;
    animation-timing-function: cubic-bezier(0.25, 0.5, 1, 1);
  }
  50% {
    stroke-dashoffset: 471.08;
    animation-timing-function: cubic-bezier(0, 0, 0.75, 0.5);
  }
  75% {
    stroke-dashoffset: 706.62;
    animation-timing-function: cubic-bezier(0.25, 0.5, 1, 1);
  }
  100% {
    stroke-dashoffset: 942.16;
  }
}
#eDlETs86x0k17_ts {
  animation: eDlETs86x0k17_ts__ts 1200ms linear infinite normal forwards;
}
@keyframes eDlETs86x0k17_ts__ts {
  0% {
    transform: translate(300px, 300px) rotate(180deg) scale(1, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  50% {
    transform: translate(300px, 300px) rotate(180deg) scale(1, 1.3);
  }
  100% {
    transform: translate(300px, 300px) rotate(180deg) scale(1, 1);
  }
}
#eDlETs86x0k17 {
  animation: eDlETs86x0k17_s_do 1200ms linear infinite normal forwards;
}
@keyframes eDlETs86x0k17_s_do {
  0% {
    stroke-dashoffset: 0;
    animation-timing-function: cubic-bezier(0, 0, 0.75, 0.5);
  }
  25% {
    stroke-dashoffset: 235.54;
    animation-timing-function: cubic-bezier(0.25, 0.5, 1, 1);
  }
  50% {
    stroke-dashoffset: 471.08;
    animation-timing-function: cubic-bezier(0, 0, 0.75, 0.5);
  }
  75% {
    stroke-dashoffset: 706.62;
    animation-timing-function: cubic-bezier(0.25, 0.5, 1, 1);
  }
  100% {
    stroke-dashoffset: 942.16;
  }
}
#eDlETs86x0k18_ts {
  animation: eDlETs86x0k18_ts__ts 1200ms linear infinite normal forwards;
}
@keyframes eDlETs86x0k18_ts__ts {
  0% {
    transform: translate(300px, 300px) rotate(180deg) scale(1, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  50% {
    transform: translate(300px, 300px) rotate(180deg) scale(1, 1.3);
  }
  100% {
    transform: translate(300px, 300px) rotate(180deg) scale(1, 1);
  }
}
#eDlETs86x0k18 {
  animation: eDlETs86x0k18_s_do 1200ms linear infinite normal forwards;
}
@keyframes eDlETs86x0k18_s_do {
  0% {
    stroke-dashoffset: 0;
    animation-timing-function: cubic-bezier(0, 0, 0.75, 0.5);
  }
  25% {
    stroke-dashoffset: 235.54;
    animation-timing-function: cubic-bezier(0.25, 0.5, 1, 1);
  }
  50% {
    stroke-dashoffset: 471.08;
    animation-timing-function: cubic-bezier(0, 0, 0.75, 0.5);
  }
  75% {
    stroke-dashoffset: 706.62;
    animation-timing-function: cubic-bezier(0.25, 0.5, 1, 1);
  }
  100% {
    stroke-dashoffset: 942.16;
  }
}
#eDlETs86x0k19_ts {
  animation: eDlETs86x0k19_ts__ts 1200ms linear infinite normal forwards;
}
@keyframes eDlETs86x0k19_ts__ts {
  0% {
    transform: translate(300px, 300px) scale(2.206103, 2.206103);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  50% {
    transform: translate(300px, 300px) scale(1.565937, 1.565937);
  }
  100% {
    transform: translate(300px, 300px) scale(2.206103, 2.206103);
  }
}
#eDlETs86x0k19 {
  animation: eDlETs86x0k19_c_o 1200ms linear infinite normal forwards;
}
@keyframes eDlETs86x0k19_c_o {
  0% {
    opacity: 0.25;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  50% {
    opacity: 0.3;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  100% {
    opacity: 0.25;
  }
}
#eDlETs86x0k21_tr {
  animation: eDlETs86x0k21_tr__tr 1200ms linear infinite normal forwards;
}
@keyframes eDlETs86x0k21_tr__tr {
  0% {
    transform: translate(300px, 300px) rotate(250deg);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  50% {
    transform: translate(300px, 300px) rotate(270deg);
  }
  100% {
    transform: translate(300px, 300px) rotate(250deg);
  }
}
</style>
